import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const VideoSection = memo(({ theme, sectionId }) => {
  const VideoSectionLayout = dynamic(() => import(/* webpackChunkName: 'VideoSectionLayout' */ `../../../../../containers/home-page/views/sections/video/layouts/${theme.layout}`))

  return (
    <VideoSectionLayout sectionId={sectionId} />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

VideoSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number
}

export default VideoSection
