import { connect } from 'react-redux'

import CarouselSection from 'components/home-page/views/sections/carousel'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme
})

export default connect(mapStateToProps)(CarouselSection)
