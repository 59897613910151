import { connect } from 'react-redux'

import WhosListeningSection from 'components/home-page/views/sections/whos-listening'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme
})

export default connect(mapStateToProps)(WhosListeningSection)
