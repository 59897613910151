import { connect } from 'react-redux'

import TitleWithDescriptionSection from 'components/home-page/views/sections/title-with-description'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme
})

export default connect(mapStateToProps)(TitleWithDescriptionSection)
