import { connect } from 'react-redux'

import KeyDatesSection from 'components/home-page/views/sections/key-dates'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme
})

export default connect(mapStateToProps)(KeyDatesSection)
