import { connect } from 'react-redux'

import Index from 'components/home-page/views/sections/navigations'

const mapStateToProps = (state, ownProps) => {
  const { theme } = state.homePageRevision.revision.sections[ownProps.sectionId]
  return {
    isGoogleTranslateEnabled:
      state.siteSettings.settings.googleTranslationEnabled,
    backgroundColor: theme.mainNavbarBackgroundColor,
    textColor: theme.mainNavbarTextColor,
    brandColor: state.theme.theme.brandColor
  }
}

export default connect(mapStateToProps)(Index)
