import { connect } from 'react-redux'
import find from 'lodash/find'

import FooterSection from 'components/home-page/views/sections/footer'
import fontFamilies from 'configs/font-families'

const mapStateToProps = (state, ownProps) => {
  const fetchedContentFont = find(fontFamilies, (f) => f.key === state.theme.theme.contentFont)

  const footerProps = {
    textColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.textColor,
    fontFamily: (fetchedContentFont && fetchedContentFont.name) || fontFamilies[0].name,
    fontWeight: state.homePageRevision.revision.sections[ownProps.sectionId].theme.fontWeight,
    fontSize: state.homePageRevision.revision.sections[ownProps.sectionId].theme.fontSize,
    layout: state.homePageRevision.revision.sections[ownProps.sectionId].theme.layout
  }

  return footerProps
}

export default connect(mapStateToProps)(FooterSection)
