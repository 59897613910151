import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const WhosListeningSection = memo(({ theme, sectionId }) => {
  const WhosListeningLayout = dynamic(() => import(/* webpackChunkName: 'WhosListeningSectionLayout' */ `../../../../../containers/home-page/views/sections/whos-listening/layouts/${theme.layout}`))

  return (
    <WhosListeningLayout sectionId={sectionId} />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

WhosListeningSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number
}

export default WhosListeningSection
