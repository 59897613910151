import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const BannerSection = memo(({ theme, sectionId }) => {
  const BannerLayout = dynamic(() => import(/* webpackChunkName: 'BannerSectionLayout' */ `../../../../../containers/home-page/views/sections/banner/layouts/${theme.layout}`))

  return (
    <BannerLayout
      sectionId={sectionId}
    />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

BannerSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number
}

export default BannerSection
