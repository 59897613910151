import { connect } from 'react-redux'

import ImportantLinksSection from 'components/home-page/views/sections/important-links'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme
})

export default connect(mapStateToProps)(ImportantLinksSection)
