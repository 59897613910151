import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  fetchProjectsForAE,
  resetProjectsPage,
  updateFilters,
  updateQuery
} from 'actions/projects'
import ProjectFinderSection from 'components/home-page/views/sections/project-finder'

const constructArrayForProjectSection = (projects) => {
  const serializedProjectArray = []

  projects.map((project, index) => {
    const projectObject = {
      id: project.id,
      image: project.imageUrl,
      title: project.name,
      description: project.description,
      permalink: project.permalink,
      state: project.state,
      publishedAt: project.publishedAt,
      platformAnalyticsTagList: project.platformAnalyticsTagList
    }

    serializedProjectArray.push(projectObject)
  })

  return serializedProjectArray
}

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  projects: constructArrayForProjectSection(state.projects.projects),
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme,
  tags: state.projectTags.tags
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetProjectsPage,
      fetchProjectsForAE,
      updateFilters,
      updateQuery
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectFinderSection)
