import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const FooterSection = memo(({ layout, sectionId }) => {
  const FooterLayout = dynamic(() => import(/* webpackChunkName: 'FooterSectionLayout' */ `../../../../../containers/home-page/views/sections/footer/layouts/${layout}`))

  return (
    <FooterLayout sectionId={sectionId} />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

FooterSection.propTypes = {
  layout: PropTypes.string,
  sectionId: PropTypes.number
}

export default FooterSection
